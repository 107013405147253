import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import './assets/styles/main.a23bbfe635d7a18de331.css';
import './assets/styles/extra.css';

import MainHeader from './partials/MainHeader';
import MainFooter from './partials/MainFooter';

// import papaparse from 'papaparse';

import Rollbar from 'rollbar';

import firebase from 'firebase/app';
// import * as firebase from 'firebase';
// import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAb7w-xWyUHXyHozOboZaU_A8rC_VVuFQU",
  authDomain: "mlw-firebase.firebaseapp.com",
  databaseURL: "https://mlw-firebase.firebaseio.com",
  projectId: "mlw-firebase",
  storageBucket: "mlw-firebase.appspot.com",
  messagingSenderId: "987939171370",
  appId: "1:987939171370:web:a7f77dfdb6b57e53672753",
  measurementId: "G-QSQJC9CN9X"
};

firebase.initializeApp(firebaseConfig);

new Rollbar({
  accessToken: '47e6408d772d4437a9c1d6a3c452a9d4',
  captureUncaught: true,
  captureUnhandledRejections: true
});

// const db = firebase.firestore();

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {

    const el = function(selector) {
      if(!(this instanceof el)) return new el(selector);
      this.selector = selector;
      if (this.info()) {
        this.top = this.info().top;
        this.bottom = this.info().bottom;
        this.width = this.info().width;
        this.height = this.info().height;
      }
    }

    el.prototype = {
      me: function() {
        if (!document.querySelector(this.selector)) return;
        return document.querySelectorAll(this.selector).length > 1 ? document.querySelectorAll(this.selector) : document.querySelector(this.selector);
      },
      info: function() {
        if (!document.querySelector(this.selector)) return;
        return document.querySelector(this.selector).getBoundingClientRect();
      }
    }

    window.requestAnimationFrame = window.requestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.msRequestAnimationFrame
    || function(f) { setTimeout(f, 1000/60); }

    var bubble1 = el('[data-parallax-element="element1"]');
    var bubble2 = el('[data-parallax-element="element2"]');

    function parallaxbubbles() {
     if (!bubble1.me()) return;
     var scrolltop = window.pageYOffset;
     bubble1.me().style.top = (40-(scrolltop * .1)) + 'px';
     bubble2.me().style.top = (250-(scrolltop * .3)) + 'px';
    }

    window.addEventListener('scroll', function() {
     requestAnimationFrame(parallaxbubbles);
    }, false);

  }, [])

  return (
    <>
      <MainHeader />

      <main>{children}</main>

      {/* <input
        type="file"
        onChange={({ target }) => {
          const reader = new FileReader();
          reader.addEventListener('load', (event) => {
            const data = event.target.result;
            papaparse.parse(data, {
              worker: true,
              complete: ({ data }) => {
                let documents = [];
                let header = data[0];
                data.forEach((row, rowIndex) => {
                  if (rowIndex === 0) return;
                  let object = {};
                  row.forEach((col, colIndex) => {
                    const name = header[colIndex].toLowerCase();
                    let value = row[colIndex];
                    if (name === 'document') value = value.replace(/\-|\.|\//g, '');
                    if (name === 'children') value = value * 1;
                    object[name] = value;
                  });
                  object.picturesFolder = object.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                  object.picturesFolder = object.picturesFolder.replace(/\-|\,|\./g, '');
                  object.picturesFolder = object.picturesFolder.replace(/\s/g, '-');
                  object.picturesFolder = object.picturesFolder.replace(/--/g, '-');
                  object.picturesFolder = object.picturesFolder.toLowerCase();
                  documents.push(object);
                })
                documents.forEach((doc) => {
                  doc.donations = 0;
                  db.collection('institutions').doc(String(doc.document)).set(doc);
                });
              }
            });
          });
          reader.readAsText(target.files[0]);
        }}
      /> */}

      {/* <input
        type="file"
        onChange={({ target }) => {
          const reader = new FileReader();
          const header = ['code', 'material', 'description', 'color', 'size', 'ean', 'brand', 'saleOrder', 'season', 'collection', 'date', 'nfe', 'cnpj', 'clientName', 'city', 'state', 'zipCode', 'address', 'month'];
          let totalLines = 1;
          let acumulator = [];
          let lastAcumulator = [];
          let batch = db.batch();
          reader.addEventListener('load', (event) => {
            const data = event.target.result;
            papaparse.parse(data, {
              step: ({ data }, parser) => {
                let object = {};
                data.forEach((item, index) => {
                  let name = header[index];
                  let value = item;
                  object[name] = value;
                });
                acumulator.push(String(object.code));
                const barcodes = db.collection('barcodes').doc(String(object.code));
                if (totalLines === 500) {
                  parser.pause();
                  batch.commit()
                  .then(() => {
                    lastAcumulator.push(acumulator.join(','));
                    totalLines = 1;
                    acumulator = [];
                    batch = db.batch();
                    parser.resume();
                    console.log('Committed');
                  }).catch((err) => {
                    console.error('batch error', err);
                  });
                } else {
                  batch.set(barcodes, object);
                  totalLines++;
                }
              },
              complete: () => {
                console.log(lastAcumulator);
              }
            });
          });
          reader.readAsText(target.files[0]);
        }}
      /> */}

      <MainFooter />
      <div
        id="parallax-body"
        className="parallax-element"
        style={{ backgroundImage: `url(${require('../images/backgrounds/parallax-01.svg')})` }}
        data-parallax-element="element1"
      />
      <div
        id="parallax-body"
        className="parallax-element"
        style={{ backgroundImage: `url(${require('../images/backgrounds/parallax-02.svg')})` }}
        data-parallax-element="element2"
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
