import React, { useEffect, useState, useRef } from 'react';

import { Link } from '@reach/router';

const MainHeader = () => {

  const mainHeaderRef = useRef();

  const [isOpen, toggleMenu] = useState(false);
  const [isHome, setIfIsHome] = useState(false);

  useEffect(() => {
    if (mainHeaderRef && mainHeaderRef.current) {
      window.addEventListener('scroll', fixedHeaderHandler);
    }
    fixedHeaderHandler();
    return () => {
      window.removeEventListener('scroll', fixedHeaderHandler);
    }
  }, [mainHeaderRef]);

  useEffect(() => {

    setIfIsHome(window.location.pathname === '/');

    setTimeout(() => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', (event) => {
          event.preventDefault();
          toggleMenu();
          document.querySelector(anchor.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        });
      });
    }, 130);
  }, []);

  function fixedHeaderHandler() {
    if( window.pageYOffset > (25) ) {
      document.querySelector('.main-header').classList.add('is-compact');
    }
    if( window.pageYOffset < (25) ) {
      document.querySelector('.main-header').classList.remove('is-compact');
    }
  }

  return (
    <header ref={mainHeaderRef} className="main-header">

      <div className="container">

        <nav className="navbar" role="navigation" aria-label="main navigation" >
          <div className="navbar-brand">

            <Link to="/"  className="navbar-item is-brand">
              <figure
                className="image is-logo-header"
                style={{ backgroundImage: `url(${require('../../images/logos/logo-header.svg')})` }}>
                <img src={require('../../images/logos/logo-header.svg')} alt="Logo Malwee" />
              </figure>
            </Link>

            <button
              onClick={() => toggleMenu(!isOpen)}
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          </div>

          <div className={`navbar-menu${isOpen ? ' is-active' : ''}`}>

            <button
              className="menu-close"
              onClick={() => toggleMenu(!isOpen)}>
              <svg
                enableBackground="new 0 0 128 128"
                height="128px"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 128 128"
                width="128px"
                xmlns="http://www.w3.org/2000/svg">
                <g>
                  <g>
                    <path d="M84.815,43.399c-0.781-0.782-2.047-0.782-2.828,0L64.032,61.356L46.077,43.399c-0.781-0.782-2.047-0.782-2.828,0 c-0.781,0.781-0.781,2.047,0,2.828l17.955,17.957L43.249,82.141c-0.781,0.78-0.781,2.047,0,2.828    c0.391,0.39,0.902,0.585,1.414,0.585s1.023-0.195,1.414-0.585l17.955-17.956l17.955,17.956c0.391,0.39,0.902,0.585,1.414,0.585    s1.023-0.195,1.414-0.585c0.781-0.781,0.781-2.048,0-2.828L66.86,64.184l17.955-17.957C85.597,45.447,85.597,44.18,84.815,43.399z     M64.032,14.054c-27.642,0-50.129,22.487-50.129,50.127c0.002,27.643,22.491,50.131,50.133,50.131    c27.639,0,50.125-22.489,50.125-50.131C114.161,36.541,91.674,14.054,64.032,14.054z M64.036,110.313h-0.002    c-25.435,0-46.129-20.695-46.131-46.131c0-25.435,20.693-46.127,46.129-46.127s46.129,20.693,46.129,46.127 C110.161,89.617,89.47,110.313,64.036,110.313z"/>
                  </g>
                </g>
              </svg>
            </button>

            <div className="navbar-end">

              {isHome ? (
                <>
                  <a
                    href="#next"
                    className="navbar-item click-inside">
                    Como participar
                  </a>
                  <a
                    href="#instituicoes"
                    className="navbar-item click-inside">
                    ONGs participantes
                  </a>
                  <a
                    href="#numeros"
                    className="navbar-item click-inside">
                    Números da campanha
                  </a>
                </>
              ) : (
                <Link
                  to="/"
                  className="navbar-item">
                  Voltar para o início
                </Link>
              )}

              <a href="https://www.malwee.com.br/institucional/nossas-lojas#2" className="navbar-item" target="blank">
                Lojas perto de você
              </a>
            </div>

          </div>
        </nav>

      </div>

      <div className="container visible-desktop-false" />

    </header>
  );
};

export default MainHeader;